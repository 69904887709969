    * {
        padding: 0;
        box-sizing: border-box;
    }
    
    html {
        scroll-behavior: smooth;
    }
    
    .App {
        text-align: center;
        width: 100%;
        height: 100%;
        z-index: -1000;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
    }
    
    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        background-color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
    }
    
    p {
        line-height: 1.5;
    }
    
     :root {
        --white: #fff;
        --black: #000;
        --lightBlue: rgb(0, 152, 219);
        --blue: #0B6D9E;
        --e-color-1: rgb(6, 107, 153);
        --e-color-2: rgb(18, 134, 193);
        --e-color-3: rgb(0, 152, 219);
        --e-color-4: rgb(21, 178, 185);
        --e-color-5: rgb(9, 174, 140);
        --e-color-6: rgb(36, 181, 122);
        --e-color-7: rgb(68, 169, 37);
        --e-color-8: rgb(52, 150, 91);
        --darkBlue: #000099;
        --green: rgb(21, 178, 185);
        --greenLeaf: rgb(68, 169, 37);
        --parrotGreen: rgb(68, 169, 37);
    }
    /* rgb(18, 134, 193); */
    /* --purple: #9995c8;
    --lime: #a6ce37;
    
    --greenMint: #0b6d5e;
    
    --darkBlueWave: rgba(0, 0, 153, .7);
}*/