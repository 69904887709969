    .itemsContainer {
        position: fixed;
        background: var(--white);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100%;
        z-index: 49;
        padding-top: 100px;
        .itemContainer {
            width: 75%;
            margin: 10px auto;
            cursor: pointer;
            transition: all 0.2s ease-out;
            &:hover {
                background-color: var(--green);
                h2 {
                    color: white;
                }
            }
            h2 {
                color: var(--blue);
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.3rem;
                color: var(--green);
                &::before {
                    content: "";
                }
                height: 50px;
                z-index: 1;
            }
            border-radius: 5px;
            border: 1px solid var(--green);
        }
        .scrollableContainer {
            width: 100%;
            overflow: auto;
        }
    }
    
    .hideItemsContainer {
        display: none;
    }
    
    @media (min-width: 1024px) {
        .itemsContainer {
            display: none;
        }
    }